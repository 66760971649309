<template>
  <div>
    <b-alert
      show
      variant="warning"
      style="margin-bottom:20px"
    >
      <div class="text-center">

        <b-card
          title="Finalizing..."
          sub-title="Registration not complete."
        >
          <b-card-text>
            We have sent an E-mail message with an ACCOUNT ACTIVATION button/link to <b>{{ email }}</b>.
          </b-card-text>

          <b-card-text>Please ACTIVATE your account for using TrafficManager!</b-card-text>
          <br>

          <b-button
            variant="success"
            :disabled="sending"
            @click="resendActivation"
          >
            <span v-if="sending">
              <b-spinner
                small
                type="grow"
              />
              Sending email...
            </span>
            <span v-else>Resend Activation Link</span>
          </b-button>
        </b-card>
      </div>

    </b-alert>
  </div>
</template>

<script>
import {
  BAlert, BCard, BCardText, BButton, BSpinner,
} from 'bootstrap-vue'
import axios from '@/libs/axios' // eslint-disable-line

export default {
  components: {
    BAlert, BCard, BCardText, BButton, BSpinner,
  },

  data() {
    return {
      sending: false,
      email: '',
      uid: 0,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
  },
  mounted() {
    const refreshId = setInterval(() => {
      if (this.user) {
        this.email = this.user.email
        this.uid = this.user.uid
        clearInterval(refreshId)
      }
    }, 500)
  },
  methods: {
    resendActivation() {
      this.sending = true
      // const uri = `/user_resend_activation_mail/${this.uid}`
      const uri = '/api/account/resend_code'
      axios.get(uri)
        .then(res => { // eslint-disable-line
          this.sending = false
          this.notify({
            text: 'Email sent. Please check your email to active your account.',
            variant: 'success',
          })
        })
    },
  },
}
</script>

<style lang="scss">
</style>
